.container {
  position: fixed;
  display: flex;

  width: 100vw;
  height: 100vh;
  z-index: 4444;

  top: 0;
  left: 0;

  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.6);
}