@import 'swiper/swiper-bundle.min.css';

.swiper-container {
  @apply flex flex-col;

  position: unset;
}

.swiper-pagination {
  @apply relative order-2 bottom-0 mt-14 !important;
}

.swiper-pagination-bullet-active {
  @apply bg-floor-green;
}
