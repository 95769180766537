html,
body {
  font-display: optional;
}

body {
  @apply pretty-scrollbar break-words antialiased text-black bg-floor-white;

  text-rendering: optimizeLegibility;
}

a,
input,
button,
textarea {
  color: currentColor;
}
