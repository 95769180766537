:root {
  --container-spacing: 1.4rem;
  --content-max-width: 1440px;
}

@screen sm {
  :root {
    --container-spacing: 2rem;
  }
}

@screen md {
  :root {
    --container-spacing: 3.5rem;
  }
}

@screen lg {
  :root {
    --container-spacing: 4rem;
  }
}

@screen xl {
  :root {
    --container-spacing: 5rem;
  }
}

@screen 2xl {
  :root {
    --container-spacing: 6rem;
  }
}
