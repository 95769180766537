.spacing {
  padding-left: var(--container-spacing);
  padding-right: var(--container-spacing);
  padding-top: var(--container-spacing);
  padding-bottom: var(--container-spacing);
}

.spacing-l {
  padding-left: var(--container-spacing);
}

.spacing-r {
  padding-right: var(--container-spacing);
}

.spacing-x {
  padding-left: var(--container-spacing);
  padding-right: var(--container-spacing);
}

.spacing-y {
  padding-top: var(--container-spacing);
  padding-bottom: var(--container-spacing);
}
